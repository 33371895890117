//
// useGetSidebarTree.ts
//

/**
 * generates the sidebar tree structure based on the current context (project or no project).
 * If the project ID is not defined in the URL, it means the user has not selected a project.
 * If the project ID is present, the user is navigating within a specific project.
 * The sidebar tree structure is built accordingly.
 */

//sqlitecloud custom hooks
import {
  useGetProject,
  useGetSqlitecloudCompanyUser,
} from "@custom-hooks/useApi";

import BackupsIcon from "../svg-icon/backups-icon.svg";
import BillingIcon from "../svg-icon/billing-icon.svg";
import ConsoleIcon from "../svg-icon/console-icon.svg";
import DatabaseIcon from "../svg-icon/database-icon.svg";
import EdgeFunctionsIcon from "../svg-icon/edge-functions-icon.svg";
import LogsIcon from "../svg-icon/logs-icon.svg";
import NodesIcon from "../svg-icon/nodes-icon.svg";
import ProjectsIcon from "../svg-icon/projects-icon.svg";
import QueryAnalyzerIcon from "../svg-icon/query-analyzer-icon.svg";
import SettingsIcon from "../svg-icon/settings-icon.svg";
import TeamIcon from "../svg-icon/team-icon.svg";
import WebhooksIcon from "../svg-icon/webhooks-icon.svg";
import WebliteIcon from "../svg-icon/weblite-icon.svg";

// Define the structure of a SidebarItem with optional children
type SidebarItem = {
  href: string;
  slug: string;
  title: string;
  icon?: any;
  children?: SidebarItem[];
  experimental?: boolean;
} | null;

// Define the structure of a SidebarTree object
interface SidebarTree {
  title: string;
  pages: SidebarItem[];
}

// Generates the sidebar tree based on whether a project is selected or not
const useGetSidebarTree = (): SidebarTree | undefined => {
  // Get the current project data from the hook
  const { project } = useGetProject();
  // Check if the user belongs to a Sqlitecloud company
  const isSqlitecloudCompanyUser = useGetSqlitecloudCompanyUser();

  if (project) {
    // Build the sidebar structure for a project-specific view
    const projectId = project.id;
    const pages: SidebarItem[] = [
      {
        href: `/projects/${projectId}/nodes`,
        slug: "nodes",
        title: "Nodes",
        icon: NodesIcon,
      },
      {
        href: `/projects/${projectId}/databases`,
        slug: "databases",
        title: "Databases",
        icon: DatabaseIcon,
      },
      {
        href: `/projects/${projectId}/console`,
        slug: "console",
        title: "Console",
        icon: ConsoleIcon,
      },
      {
        href: `/projects/${projectId}/edge-functions`,
        slug: "edge-functions",
        title: "Edge Functions",
        icon: EdgeFunctionsIcon,
      },
      {
        href: `/projects/${projectId}/webhooks`,
        slug: "webhooks",
        title: "Webhooks",
        icon: WebhooksIcon,
      },
      {
        href: `/projects/${projectId}/backups`,
        slug: "backups",
        title: "Backups",
        icon: BackupsIcon,
      },
      {
        href: `/projects/${projectId}/logs`,
        slug: "logs",
        title: "Logs",
        icon: LogsIcon,
      },
      isSqlitecloudCompanyUser
        ? {
            href: `/projects/${projectId}/weblite`,
            slug: "weblite",
            title: "Weblite",
            icon: WebliteIcon,
          }
        : null,
      {
        href: `/projects/${projectId}/query-analyzer`,
        slug: "query-analyzer",
        title: "Query Analyzer",
        icon: QueryAnalyzerIcon,
      },
      {
        href: "",
        slug: "settings",
        title: "Settings",
        icon: SettingsIcon,
        children: [
          {
            href: `/projects/${projectId}/settings/users`,
            slug: "users",
            title: "Users",
          },
          {
            href: `/projects/${projectId}/settings/roles`,
            slug: "roles",
            title: "Roles",
          },
          {
            href: `/projects/${projectId}/settings/api-keys`,
            slug: "api-keys",
            title: "API Keys",
          },
          isSqlitecloudCompanyUser
            ? {
                href: `/projects/${projectId}/settings/environment-variables`,
                slug: "environment-variables",
                title: "Env Variables",
                experimental: true,
              }
            : null,
          // These advanced settings are only available for Sqlitecloud company users
          isSqlitecloudCompanyUser
            ? {
                href: `/projects/${projectId}/settings/advanced-settings`,
                slug: "advanced-settings",
                title: "Advanced",
                experimental: true,
              }
            : null,
          isSqlitecloudCompanyUser
            ? {
                href: `/projects/${projectId}/settings/ip-restriction`,
                slug: "ip-restriction",
                title: "IP Restriction",
                experimental: true,
              }
            : null,
          isSqlitecloudCompanyUser
            ? {
                href: `/projects/${projectId}/settings/plugins`,
                slug: "plugins",
                title: "Plugins",
                experimental: true,
              }
            : null,
          isSqlitecloudCompanyUser
            ? {
                href: `/projects/${projectId}/settings/commands`,
                slug: "commands",
                title: "Commands",
                experimental: true,
              }
            : null,
        ].filter(Boolean) as SidebarItem[], // Filter out any null values
      },
    ];
    // Return the sidebar object with the project title and pages
    return {
      title: project.name,
      pages: pages,
    };
  } else {
    // Build the sidebar structure for the organization-level view (no specific project)
    const pages: SidebarItem[] = [
      {
        href: `/`,
        slug: "",
        title: "Projects",
        icon: ProjectsIcon,
      },
      {
        href: `/team`,
        slug: "team",
        title: "Team",
        icon: TeamIcon,
      },
      {
        href: `/billing`,
        slug: "billing",
        title: "Billing",
        icon: BillingIcon,
      },
    ];
    /**
     * TEMPORARY DISABLED HOME SIDEBAR
     * WILL BE ENABLED WHEN READY THE NEW DESIGN
     */
    // Return the sidebar object without a title (no project selected)
    // return {
    //   title: "",
    //   pages: pages,
    // };
    return undefined;
  }
};

export { useGetSidebarTree };
