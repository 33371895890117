//
// client-side-fetcher.ts
//

// posthog
import posthog from "posthog-js";
// formbricks
import formbricks from "@formbricks/js/app";

// Define the signOut function
const signOut = async (
  callbackUrl: string = "/auth/sign-in"
): Promise<void> => {
  try {
    // Fetch the CSRF token
    const resCsrfToken = await fetch("/api/auth/csrf");
    const csrfToken = await resCsrfToken.json();

    // Perform sign out request
    await fetch("/api/auth/signout", {
      method: "POST",
      body: JSON.stringify(csrfToken),
      headers: {
        "Content-Type": "application/json",
      },
    });

    // After successful logout, reset PostHog
    posthog.reset();

    // After successful logout, reset Formbricks
    try {
      formbricks.logout();
    } catch (error) {
      console.error("Formbricks logout error:", error);
    }

    // Redirect to sign-in page
    window.location.href = callbackUrl;
  } catch (error) {
    console.error("Sign-out error:", error);
  }
};

// Export the signOut function
export { signOut };
